import logo from './logo.jpeg'

let App = () => {
  return <div className='center-main'>
    <div className='center-box'>
      <div className='container'>
        <div className='col-md-4 offset-md-4'>
          <img className='logo img-fluid' src={logo} /></div>
        </div>
    </div>
  </div>
}

export default App
